// src/hooks/useIdleTimer.js
import { useEffect } from 'react';

function useIdleTimer(logout, timeout = 300000) {
  useEffect(() => {
    let timeoutId;

    const resetTimer = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(logout, timeout);
    };

    const events = ['mousemove', 'keydown', 'mousedown', 'touchstart'];

    events.forEach((event) => {
      window.addEventListener(event, resetTimer);
    });

    resetTimer(); // Initialize timer on mount

    return () => {
      clearTimeout(timeoutId);
      events.forEach((event) => {
        window.removeEventListener(event, resetTimer);
      });
    };
  }, [logout, timeout]);
}

export default useIdleTimer;
