// src/components/NewSubmission.js
import React, { useState, useEffect } from 'react';
import axios from '../axiosInstance';
import { useNavigate } from 'react-router-dom';

function NewSubmission({ user }) {
  const [region, setRegion] = useState('International');
  const [uploadDocument, setUploadDocument] = useState(null);
  const [title, setTitle] = useState('');
  const [excludeBibliography, setExcludeBibliography] = useState(false);
  const [excludeQuotes, setExcludeQuotes] = useState(false);
  const [excludeSmallMatchesMethod, setExcludeSmallMatchesMethod] = useState('disabled');
  const [excludeSmallMatchesValueWords, setExcludeSmallMatchesValueWords] = useState('');
  const [excludeSmallMatchesValuePercentage, setExcludeSmallMatchesValuePercentage] = useState('');
  const [quotaUsed, setQuotaUsed] = useState(0);
  const [quotaLimit, setQuotaLimit] = useState(50); // Assuming a default quota limit
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [progress, setProgress] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    // Fetch quota information from the backend if needed
    const fetchQuota = async () => {
      try {
        const response = await axios.get('/quota', {
          headers: { Authorization: `Bearer ${user.token}` },
        });
        setQuotaUsed(response.data.used);
        setQuotaLimit(response.data.limit);
      } catch (err) {
        console.error('Error fetching quota information:', err);
      }
    };

    fetchQuota();
  }, [user.token]);

  // const handleSubmit = async (event) => {
  //   event.preventDefault();

  //   if (!region || !uploadDocument) {
  //     alert('Please fill in all required fields.');
  //     return;
  //   }

  //   setIsSubmitting(true);

  //   // Create an object with all the form data except the file
  //   const formDataObject = {
  //     title: title || uploadDocument.name,
  //     region,
  //     excludeBibliography,
  //     excludeQuotes,
  //     excludeSmallMatchesMethod,
  //     excludeSmallMatchesValueWords,
  //     excludeSmallMatchesValuePercentage,
  //   };

  //   // Create FormData and append 'file' and 'data' fields
  //   const formData = new FormData();
  //   formData.append('file', uploadDocument); // 'file' matches backend expectation
  //   formData.append('data', JSON.stringify(formDataObject)); // Bundle other data into 'data' field

  //   try {
  //     const response = await axios.post('/submit', formData, {
  //       headers: {
  //         Authorization: `Bearer ${user.token}`,
  //         // Do not set 'Content-Type' explicitly when using FormData
  //       },
  //       onUploadProgress: (progressEvent) => {
  //         const percentComplete = Math.round((progressEvent.loaded * 100) / progressEvent.total);
  //         setProgress(percentComplete);
  //       },
  //     });

  //     // After successful submission, navigate to the submissions page
  //     navigate('/submissions');
  //   } catch (err) {
  //     console.error('Submission failed:', err.response || err);
  //     alert(err.response?.data?.message || 'An error occurred during submission.');
  //   } finally {
  //     setIsSubmitting(false);
  //     setProgress(0);
  //   }
  // };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    if (!region || !uploadDocument) {
      alert('Please fill in all required fields.');
      return;
    }
  
    setIsSubmitting(true);
  
    // Create an object with all the form data except the file
    const formDataObject = {
      title: title || uploadDocument.name,
      region,
      excludeBibliography,
      excludeQuotes,
      excludeSmallMatchesMethod,
      excludeSmallMatchesValueWords,
      excludeSmallMatchesValuePercentage,
    };
  
    // Create FormData and append 'file' and 'data' fields
    const formData = new FormData();
    formData.append('file', uploadDocument); // 'file' matches backend expectation
    formData.append('data', JSON.stringify(formDataObject)); // Bundle other data into 'data' field
  
    try {
      await axios.post('/submit', formData, {
        headers: {
          Authorization: `Bearer ${user.token}`,
          // Do not set 'Content-Type' explicitly when using FormData
        },
        onUploadProgress: (progressEvent) => {
          const percentComplete = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setProgress(percentComplete);
        },
      });
  
      // After successful submission, navigate to the submissions page
      navigate('/submissions');
    } catch (err) {
      console.error('Submission failed:', err.response || err);
      alert(err.response?.data?.message || 'An error occurred during submission.');
    } finally {
      setIsSubmitting(false);
      setProgress(0);
    }
  };
  
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const allowedTypes = [
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ];
    const maxSize = 10 * 1024 * 1024; // 10MB

    if (file) {
      if (!allowedTypes.includes(file.type)) {
        alert('Invalid file type. Please upload a PDF or Word document.');
        e.target.value = null; // Clear the input
        return;
      }
      if (file.size > maxSize) {
        alert('File size exceeds the limit of 10MB.');
        e.target.value = null; // Clear the input
        return;
      }
      setUploadDocument(file);
      setTitle(file.name); // Set title to the original file name
    }
  };

  // Update visibility of exclude small matches value inputs
  const showExcludeWords = excludeSmallMatchesMethod === 'by_words';
  const showExcludePercentage = excludeSmallMatchesMethod === 'by_percentage';

  return (
    <div className="container my-4">
      <div className="card">
        <div className="card-body">
          <h3 className="card-title">Make new submission</h3>
          {/* <h6
            className="card-subtitle text-muted"
            onClick={() => navigate('/plan')}
            style={{ cursor: 'pointer' }}
          >
            Your Quota: {quotaUsed} / {quotaLimit}
            <span className="badge bg-white text-dark ms-2">Reset everyday</span>
          </h6> */}
          <hr />
          <div className="d-grid gap-2">
            <form id="submission-form" onSubmit={handleSubmit}>
              {/* Region Field */}
              <div className="mb-3">
                <fieldset>
                  <legend className="form-label">
                    Region<span className="text-danger">*</span>
                  </legend>
                  <div>
                    <div className="form-check">
                      <input
                        type="radio"
                        className="form-check-input"
                        name="region"
                        value="International"
                        id="region_intl"
                        required
                        checked={region === 'International'}
                        onChange={() => setRegion('International')}
                      />
                      <label htmlFor="region_intl" className="form-check-label">
                        International
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        type="radio"
                        className="form-check-input"
                        name="region"
                        value="United Kingdom"
                        id="region_uk"
                        required
                        checked={region === 'United Kingdom'}
                        onChange={() => setRegion('United Kingdom')}
                      />
                      <label htmlFor="region_uk" className="form-check-label">
                        United Kingdom
                      </label>
                    </div>
                  </div>
                </fieldset>
              </div>

              {/* Document Information */}
              <fieldset>
                <legend>Document Information</legend>
                <div className="mb-3">
                  <label htmlFor="uploadDocument" className="form-label">
                    Upload document<span className="text-danger">*</span>
                  </label>
                  <input
                    type="file"
                    name="uploadDocument"
                    className="form-control"
                    id="uploadDocument"
                    required
                    onChange={handleFileChange}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="title" className="form-label">
                    Title
                  </label>
                  <input
                    type="text"
                    name="title"
                    className="form-control"
                    id="title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>
              </fieldset>

              {/* Exclusion Options */}
              <fieldset>
                <legend>Exclusion Options</legend>
                <div className="mb-3 form-check">
                  <input
                    type="checkbox"
                    name="excludeBibliography"
                    className="form-check-input"
                    id="excludeBibliography"
                    checked={excludeBibliography}
                    onChange={(e) => setExcludeBibliography(e.target.checked)}
                  />
                  <label htmlFor="excludeBibliography" className="form-check-label">
                    Exclude Bibliography
                  </label>
                </div>
                <div className="mb-3 form-check">
                  <input
                    type="checkbox"
                    name="excludeQuotes"
                    className="form-check-input"
                    id="excludeQuotes"
                    checked={excludeQuotes}
                    onChange={(e) => setExcludeQuotes(e.target.checked)}
                  />
                  <label htmlFor="excludeQuotes" className="form-check-label">
                    Exclude Quotes
                  </label>
                </div>
                <div className="mb-3">
                  <fieldset>
                    <legend className="form-label">
                      Exclude small matches method<span className="text-danger">*</span>
                    </legend>
                    <div>
                      <div className="form-check">
                        <input
                          type="radio"
                          className="form-check-input"
                          name="excludeSmallMatchesMethod"
                          value="disabled"
                          id="excludeMethod_disabled"
                          required
                          checked={excludeSmallMatchesMethod === 'disabled'}
                          onChange={() => setExcludeSmallMatchesMethod('disabled')}
                        />
                        <label htmlFor="excludeMethod_disabled" className="form-check-label">
                          Disabled
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="radio"
                          className="form-check-input"
                          name="excludeSmallMatchesMethod"
                          value="by_words"
                          id="excludeMethod_words"
                          required
                          checked={excludeSmallMatchesMethod === 'by_words'}
                          onChange={() => setExcludeSmallMatchesMethod('by_words')}
                        />
                        <label htmlFor="excludeMethod_words" className="form-check-label">
                          By Words
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="radio"
                          className="form-check-input"
                          name="excludeSmallMatchesMethod"
                          value="by_percentage"
                          id="excludeMethod_percentage"
                          required
                          checked={excludeSmallMatchesMethod === 'by_percentage'}
                          onChange={() => setExcludeSmallMatchesMethod('by_percentage')}
                        />
                        <label htmlFor="excludeMethod_percentage" className="form-check-label">
                          By Percentage
                        </label>
                      </div>
                    </div>
                  </fieldset>
                </div>
                {showExcludeWords && (
                  <div className="mb-3">
                    <label htmlFor="excludeSmallMatchesValueWords" className="form-label">
                      Exclude small matches value (words)<span className="text-danger">*</span>
                    </label>
                    <div className="input-group">
                      <input
                        type="number"
                        name="excludeSmallMatchesValueWords"
                        value={excludeSmallMatchesValueWords}
                        min="0"
                        className="form-control"
                        required
                        onChange={(e) => setExcludeSmallMatchesValueWords(e.target.value)}
                        id="excludeSmallMatchesValueWords"
                      />
                      <span className="input-group-text">words</span>
                    </div>
                  </div>
                )}
                {showExcludePercentage && (
                  <div className="mb-3">
                    <label htmlFor="excludeSmallMatchesValuePercentage" className="form-label">
                      Exclude small matches value (percentage)<span className="text-danger">*</span>
                    </label>
                    <div className="input-group">
                      <input
                        type="number"
                        name="excludeSmallMatchesValuePercentage"
                        value={excludeSmallMatchesValuePercentage}
                        min="0"
                        className="form-control"
                        required
                        onChange={(e) => setExcludeSmallMatchesValuePercentage(e.target.value)}
                        id="excludeSmallMatchesValuePercentage"
                      />
                      <span className="input-group-text">%</span>
                    </div>
                  </div>
                )}
              </fieldset>

              <div className="alert alert-info">
                The file you are submitting will not be added to any repository.
              </div>
              <div className="d-grid gap-4">
                <button
                  type="submit"
                  className="btn btn-primary btn-block"
                  id="submit-button"
                  disabled={isSubmitting}
                >
                  {isSubmitting && (
                    <span
                      className="spinner-border spinner-border-sm me-1"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                  {isSubmitting ? 'Submitting...' : 'Submit'}
                </button>
              </div>
            </form>
            {isSubmitting && (
              <div className="progress mt-3">
                <div
                  className="progress-bar"
                  role="progressbar"
                  aria-valuenow={progress}
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style={{ width: `${progress}%` }}
                >
                  {progress}%
                </div>
              </div>
            )}
            <div className="d-grid gap-4 mb-4">
              <button
                className="btn btn-outline-primary"
                onClick={() => navigate('/submissions')}
              >
                Back to list
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewSubmission;
