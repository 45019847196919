// src/App.js
// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Signup from './components/Signup';
import Login from './components/Login';
import Submissions from './components/Submissions';
import NewSubmission from './components/NewSubmission';
import SubmissionDetails from './components/SubmissionDetails'; // Import the new component
import socket from './socket';
import PublicRoute from './components/PublicRoute';
import useIdleTimer from './hooks/useIdleTimer'; // Import the hook

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const email = localStorage.getItem('email');
    const userID = localStorage.getItem('userID');

    if (token && email && userID) {
      setUser({ token, email, userID });
      // Connect to Socket.io
      socket.auth = { token };
      socket.connect();
    }

    setLoading(false);
  }, []);

  // Define the logout function
  const logout = () => {
    setUser(null);
    localStorage.removeItem('token');
    localStorage.removeItem('email');
    localStorage.removeItem('userID');
    socket.disconnect();
  };

  // Use the idle timer only if the user is logged in
  useIdleTimer(logout, 300000); // 5 minutes in milliseconds

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <Routes>
        <Route
          path="/signup"
          element={
            <PublicRoute user={user}>
              <Signup setUser={setUser} />
            </PublicRoute>
          }
        />
        <Route
          path="/login"
          element={
            <PublicRoute user={user}>
              <Login setUser={setUser} />
            </PublicRoute>
          }
        />
        <Route
          path="/submissions"
          element={user ? <Submissions user={user} /> : <Navigate to="/login" />}
        />
        <Route
          path="/submissions/:id"
          element={user ? <SubmissionDetails user={user} /> : <Navigate to="/login" />}
        />
        <Route
          path="/new-submission"
          element={user ? <NewSubmission user={user} /> : <Navigate to="/login" />}
        />
        
        <Route
          path="/"
          element={<Navigate to={user ? '/submissions' : '/login'} />}
        />
      </Routes>
    </Router>
  );
}

export default App;
