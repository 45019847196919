// src/components/Submissions.js
import React, { useState, useEffect } from 'react';
import axios from '../axiosInstance';
import socket from '../socket';
import { useNavigate } from 'react-router-dom';



function Submissions({ user }) {
    const [submissions, setSubmissions] = useState([]);
    const [error, setError] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10); // Default items per page
    const [totalPages, setTotalPages] = useState(1);

    const navigate = useNavigate();
  
    useEffect(() => {
        const fetchSubmissions = async () => {
          try {
            const response = await axios.get('/submissions', {
              headers: { Authorization: `Bearer ${user.token}` },
              params: {
                page: currentPage,
                limit: itemsPerPage,
              },
            });
            setSubmissions(response.data.data);
            setTotalPages(response.data.pagination.totalPages);
          } catch (err) {
            console.error('Error fetching submissions:', err);
            setError(err.response?.data?.message || 'An error occurred while fetching submissions.');
          }
        };
  
      fetchSubmissions();
  
      // Real-time updates
      socket.on('submissionUpdate', (updatedSubmission) => {
        setSubmissions((prevSubmissions) =>
          prevSubmissions.map((submission) =>
            submission._id === updatedSubmission._id ? updatedSubmission : submission
          )
        );
      });
  
      return () => {
        socket.off('submissionUpdate');
      };
    }, [user.token, currentPage, itemsPerPage]);
  
    const handleDelete = async (id) => {
      try {
        await axios.delete(`/submissions/${id}`, {
          headers: { Authorization: `Bearer ${user.token}` },
        });
        // Remove the deleted submission from the state
        setSubmissions(submissions.filter((submission) => submission._id !== id));
      } catch (err) {
        console.error('Error deleting submission:', err);
        alert(err.response?.data?.message || 'An error occurred while deleting the submission.');
      }
    };
  
    if (error) {
      return <div>Error: {error}</div>;
    }
  
    return (
      <div className="container my-4">
        <div className="card">
          <div className="card-body">
            <h3 className="card-title">Submission List</h3>
            <h6 className="card-subtitle text-muted">Tasks status will refresh automatically</h6>
            <hr />
            <div className="d-flex justify-content-end mb-4">
              <button
                className="btn btn-outline-primary text-uppercase"
                onClick={() => navigate('/new-submission')}
              >
                New Submission
              </button>
            </div>
            <div className="table-responsive">
              <table className="table text-nowrap">
                <thead>
                  <tr>
                    <th className="text-center">Title</th>
                    <th className="text-center">Word Count</th>
                    <th className="text-center">Similarity</th>
                    <th className="text-center">AI</th>
                    <th className="text-center">Status</th>
                    <th className="text-center">Started at</th>
                    <th className="text-center">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {submissions.map((submission) => (
                    <tr key={submission._id}>
                      <td className="text-center">
                        <button
                            className="btn btn-outline-info"
                            onClick={() => navigate(`/submissions/${submission._id}`)}
                        >
                            {submission.title}
                        </button>
                        </td>
                      <td className="text-center">{submission.wordCount || 'Processing...'}&nbsp;</td>
                      <td className="text-center">
                        {submission.similarity != null
                        ? `${submission.similarity}`
                        : 'Processing...'}&nbsp;
                        {submission.similarityLink && (
                          <>
                            &nbsp;
                            <a
                              className="btn btn-sm btn-outline-danger ml-2"
                              href={`/downloads/${submission.similarityLink}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              download={`similarity_${submission.title}.pdf`}
                            >
                              PDF
                            </a>
                          </>
                        )}
                      </td>
                      <td className="text-center">
                      {submission.aiWriting != null
                      ? `${submission.aiWriting}`
                      : 'N/A'}&nbsp;
                        {submission.aiWritingLink && (
                          <>
                            &nbsp; 
                            <a
                              className="btn btn-sm btn-outline-info ml-2"
                              href={`/downloads/${submission.aiWritingLink}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              download={`ai_${submission.title}.pdf`}
                            >
                              PDF
                            </a>
                          </>
                        )}
                      </td>
                      <td className="text-center">
                        {submission.status === 'SUCCESS' ? (
                          <span className="text-success">SUCCESS</span>
                        ) : (
                          <span className="text-warning">{submission.status}</span>
                        )}
                      </td>
                      <td className="text-center">
                        {new Date(submission.startedAt).toLocaleString()}
                      </td>
                      <td className="text-center">
                        <button
                          className="btn btn-sm btn-danger"
                          onClick={() => handleDelete(submission._id)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                  {submissions.length === 0 && (
                    <tr>
                      <td colSpan="7" className="text-center">
                        No submissions found.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              {/* Pagination can be implemented here if needed */}
                <div className="d-flex justify-content-between align-items-center mt-3">
                <div>
                    <label htmlFor="itemsPerPage">Items per page:</label>
                    <select
                    id="itemsPerPage"
                    className="ml-2"
                    value={itemsPerPage}
                    onChange={(e) => {
                        setItemsPerPage(parseInt(e.target.value));
                        setCurrentPage(1); // Reset to page 1 when items per page changes
                    }}
                    >
                    {[10, 20, 30, 50, 100, 500, 1000].map((size) => (
                        <option key={size} value={size}>
                        {size}
                        </option>
                    ))}
                    </select>
                </div>
                <nav>
                    <ul className="pagination mb-0">
                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={() => setCurrentPage(1)}>&laquo;</button>
                    </li>
                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={() => setCurrentPage(currentPage - 1)}>&lsaquo;</button>
                    </li>
                    <li className="page-item active">
                        <span className="page-link">{currentPage}</span>
                    </li>
                    <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={() => setCurrentPage(currentPage + 1)}>&rsaquo;</button>
                    </li>
                    <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                        <button className="page-link" onClick={() => setCurrentPage(totalPages)}>&raquo;</button>
                    </li>
                    </ul>
                </nav>
                </div>

            </div>
          </div>
        </div>
      </div>
    );
  }
  
export default Submissions;
  