// src/components/Signup.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../axiosInstance';
import countries from '../data/countries';
import Select from 'react-select';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

function Signup() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [username, setUsername] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [selectedCountry, setSelectedCountry] = useState(null); // For country selection and mobile number
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  // Options for country selector (for mobile number and country)
  const countryOptions = countries.map((country) => ({
    value: country.dial_code,
    label: (
      <div className="d-flex align-items-center">
        <img
          src={country.flag}
          alt={country.name}
          style={{ width: '20px', marginRight: '8px' }}
        />
        {country.name} ({country.dial_code})
      </div>
    ),
    code: country.code,
    name: country.name,
  }));

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedCountry) {
      setError('Please select your country.');
      return;
    }

    // Verify mobile number
    const phoneNumber = parsePhoneNumberFromString(
      mobileNumber,
      selectedCountry.code
    );

    if (!phoneNumber || !phoneNumber.isValid()) {
      setError('Please enter a valid mobile number.');
      return;
    }

    const internationalNumber = phoneNumber.number;

    try {
      // Implement signup logic here
      await axios.post('/signup', {
        firstName,
        lastName,
        username,
        phoneNumber: internationalNumber,
        email,
        password,
        country: selectedCountry.name, // Use 'country' instead of 'countryCode'
      });

      // Redirect to login page after successful signup
      navigate('/login');
    } catch (err) {
      console.error('Signup failed:', err);
      setError(err.response?.data?.message || 'An error occurred during signup.');
    }
  };

  return (
    <div
      className="container d-flex align-items-center justify-content-center min-vh-100"
      style={{
        background:
          'linear-gradient(90deg, rgba(96,165,250,1) 0%, rgba(37,99,235,1) 100%)',
      }}
    >
      <div className="card shadow-sm w-100" style={{ maxWidth: '500px' }}>
        <div className="card-body">
          <h3 className="card-title text-center mb-4">Sign Up</h3>
          {error && (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          )}
          <form onSubmit={handleSubmit}>
            {/* First Name */}
            <div className="mb-3">
              <label htmlFor="firstName" className="form-label">
                First Name<span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                id="firstName"
                required
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            {/* Last Name */}
            <div className="mb-3">
              <label htmlFor="lastName" className="form-label">
                Last Name<span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                id="lastName"
                required
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            {/* Username */}
            <div className="mb-3">
              <label htmlFor="username" className="form-label">
                Username<span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                id="username"
                required
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            {/* Country Selector */}
            <div className="mb-3">
              <label htmlFor="country" className="form-label">
                Country<span className="text-danger">*</span>
              </label>
              <Select
                options={countryOptions}
                onChange={(option) => setSelectedCountry(option)}
                isSearchable
                placeholder="-- Select Country --"
                formatOptionLabel={(option) => option.label}
                value={selectedCountry}
              />
            </div>
            {/* Mobile Number */}
            <div className="mb-3">
              <label htmlFor="mobileNumber" className="form-label">
                Mobile Number<span className="text-danger">*</span>
              </label>
              <div className="input-group">
                {selectedCountry && (
                  <span className="input-group-text">
                    {selectedCountry.value}
                  </span>
                )}
                <input
                  type="tel"
                  className="form-control"
                  id="mobileNumber"
                  required
                  value={mobileNumber}
                  onChange={(e) => setMobileNumber(e.target.value)}
                  placeholder="Enter your mobile number"
                />
              </div>
            </div>
            {/* Email Field */}
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Email address<span className="text-danger">*</span>
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                placeholder="name@example.com"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            {/* Password Field */}
            <div className="mb-3">
              <label htmlFor="password" className="form-label">
                Password<span className="text-danger">*</span>
              </label>
              <input
                type="password"
                className="form-control"
                id="password"
                placeholder="Create a password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            {/* Terms and Conditions */}
            <div className="form-check mb-3">
              <input type="checkbox" className="form-check-input" id="terms" required />
              <label className="form-check-label" htmlFor="terms">
                I agree to the{' '}
                <a href="/terms" className="text-decoration-none">
                  Terms and Conditions
                </a>
              </label>
            </div>
            {/* Submit Button */}
            <button type="submit" className="btn btn-primary w-100">
              Sign Up
            </button>
          </form>
          <p className="text-center mt-3">
            Already have an account?{' '}
            <a href="/login" className="text-decoration-none">
              Sign In
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Signup;
