// src/data/countries.js

const countries = [
    {
      name: 'United States',
      code: 'US',
      dial_code: '+1',
      flag: 'https://flagcdn.com/w20/us.png',
    },
    {
      name: 'United Kingdom',
      code: 'GB',
      dial_code: '+44',
      flag: 'https://flagcdn.com/w20/gb.png',
    },
    {
      name: 'Canada',
      code: 'CA',
      dial_code: '+1',
      flag: 'https://flagcdn.com/w20/ca.png',
    },
    {
      name: 'Australia',
      code: 'AU',
      dial_code: '+61',
      flag: 'https://flagcdn.com/w20/au.png',
    },
    {
      name: 'India',
      code: 'IN',
      dial_code: '+91',
      flag: 'https://flagcdn.com/w20/in.png',
    },
    // Add more countries as needed
  ];
  
  export default countries;
  