// src/components/SubmissionDetails.js
import React, { useState, useEffect } from 'react';
import axios from '../axiosInstance';
import { useParams, useNavigate } from 'react-router-dom';

function SubmissionDetails({ user }) {
  const { id } = useParams(); // Get the submission ID from the URL
  const [submission, setSubmission] = useState(null);
  const [error, setError] = useState('');
  const [deleting, setDeleting] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSubmission = async () => {
      try {
        const response = await axios.get(`/submissions/${id}`, {
          headers: { Authorization: `Bearer ${user.token}` },
        });
        setSubmission(response.data.data);
      } catch (err) {
        console.error('Error fetching submission details:', err);
        setError(
          err.response?.data?.message ||
            'An error occurred while fetching submission details.'
        );
      }
    };

    fetchSubmission();
  }, [id, user.token]);

  const handleDelete = async () => {
    if (window.confirm('Are you sure you want to delete this submission?')) {
      try {
        setDeleting(true);
        await axios.delete(`/submissions/${id}`, {
          headers: { Authorization: `Bearer ${user.token}` },
        });
        navigate('/submissions'); // Navigate back to the submissions list
      } catch (err) {
        console.error('Error deleting submission:', err);
        alert(
          err.response?.data?.message ||
            'An error occurred while deleting the submission.'
        );
        setDeleting(false);
      }
    }
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!submission) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container my-4">
      <div className="card">
        <div className="card-body">
          <h3 className="card-title">Submission Detail</h3>
          <h6 className="card-subtitle text-muted">{submission.title}</h6>
          <hr />
          <div className="d-grid gap-4">
            <table className="table table-borderless">
              <tbody>
                <tr>
                  <th scope="row" className="text-end col-sm-4">
                    Title:
                  </th>
                  <td className="text-start col-sm-8">{submission.title}</td>
                </tr>
                {/* Conditionally render Region if available */}
                {submission.region && (
                  <tr>
                    <th scope="row" className="text-end col-sm-4">
                      Region:
                    </th>
                    <td className="text-start col-sm-8">{submission.region}</td>
                  </tr>
                )}
                {/* Conditionally render Exclude if available */}
                {submission.exclude && (
                  <tr>
                    <th scope="row" className="text-end col-sm-4">
                      Exclude:
                    </th>
                    <td className="text-start col-sm-8">
                      <span className="badge rounded-pill text-bg-primary">
                        {submission.exclude}
                      </span>
                    </td>
                  </tr>
                )}
                <tr>
                  <th scope="row" className="text-end col-sm-4">
                    Similarity Index:
                  </th>
                  <td className="text-start col-sm-8">
                    {submission.similarity || 'Processing...'}
                    {submission.similarityLink && (
                      <a
                        href={`/downloads/${submission.similarityLink}`}
                        className="btn btn-outline-danger ms-2"
                        target="_blank"
                        rel="noopener noreferrer"
                        download={`similarity_${submission.title}.pdf`}
                      >
                        Download Similarity Report PDF
                      </a>
                    )}
                  </td>
                </tr>
                <tr>
                  <th scope="row" className="text-end col-sm-4">
                    AI Writing Index:
                  </th>
                  <td className="text-start col-sm-8">
                    {submission.aiWriting || 'Processing...'}
                    {submission.aiWritingLink && (
                      <a
                        href={`/downloads/${submission.aiWritingLink}`}
                        className="btn btn-outline-info ms-2"
                        target="_blank"
                        rel="noopener noreferrer"
                        download={`ai_${submission.title}.pdf`}
                      >
                        Download AI Writing Report PDF
                      </a>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="d-flex gap-2">
              <button
                className="btn btn-outline-danger"
                id="delete-submission"
                onClick={handleDelete}
                disabled={deleting}
              >
                {deleting && (
                  <div
                    className="spinner-border spinner-border-sm me-2"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                )}
                Delete
              </button>
              <button
                className="btn btn-primary"
                onClick={() => navigate('/submissions')}
              >
                Back
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubmissionDetails;
